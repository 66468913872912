import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import Billpayment from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "react-bootstrap";

import moment from "moment";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const [offset, setOffset] = useState(2);
    const [pageNumber, setPageNumber] = useState(1);
    const [TotalPages, setTotalPages] = useState(1);
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const fromRef = useRef<any>();
    const toref = useRef<any>();
    const [transactions, setTrasactions] = useState<any>([]);
    const userId = localStorage.getItem("userid");

    useEffect(() => {
        Submit(0);
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        // setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    const Submit = (offSet: any) => {
        setLoading(true);
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let body = {
                fromDate: fromRef?.current?.value,
                toDate: toref?.current?.value,
            };
            LoginServices.bbpsTRANSactions(
                body,
                config,
                parseInt(userId),
                offSet
            )
                .then((response: any) => {
                    console.log(response);
                    setLoading(false);
                    if (response.data.Status == 1) {
                        setTrasactions(response?.data?.BBPSTransactions);
                        setTotalPages(
                            Math.ceil(
                                response?.data?.TotalRows /
                                    response?.data?.PageSize
                            )
                        );
                        console.log(response.data);
                        setOffset(response?.data?.PageSize);
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    };
    const handlePageChange = (number: any) => {
        Submit((number - 1) * offset);
        setPageNumber(number);
    };
    const renderPaginationItems = () => {
        const items = [];
        for (let number = 1; number <= TotalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === pageNumber}
                    onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };
    console.log(TotalPages);

    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Bill Payments</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="TranscationWrapper">
                    <Billpayment active="transaction" />
                    <div className="transcations">
                        <div className="filter">
                            <div className="filter-control">
                                <label htmlFor="To Date">From Date</label>
                                <input
                                    className="input"
                                    type={"date"}
                                    name="from Date"
                                    ref={fromRef}
                                />
                            </div>
                            <div className="filter-control">
                                <label htmlFor="FromDate">To Date</label>
                                <input
                                    className="input"
                                    type={"date"}
                                    name="fromDate"
                                    ref={toref}
                                />
                            </div>

                            <button onClick={() => Submit(0)}>Submit</button>
                        </div>
                        {loading ? (
                            <PulseLoader
                                color={"#010080"}
                                loading={loading}
                                css={override}
                                size={20}
                            />
                        ) : (
                            <div className="transcationTable">
                                <table>
                                    <tr>
                                        <th>Transaction ID </th>
                                        <th>Transaction Date</th>

                                        <th>Biller Name</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        {/* <th>Print</th> */}
                                    </tr>
                                    {transactions.map((element: any) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {
                                                        element.razorpay_TransactionId
                                                    }
                                                </td>
                                                <td>
                                                    {moment(
                                                        element.transactionDateTime
                                                    ).format("DD-MM-YYYY")}
                                                </td>
                                                <td>{element.billerName}</td>
                                                <td>
                                                    &#8377; {element.amount}
                                                </td>
                                                <td>
                                                    {element.transactionStatus ==
                                                    1 ? (
                                                        <span>Success</span>
                                                    ) : (
                                                        <span>Pending</span>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </table>
                                <Pagination>
                                    <Pagination.Prev
                                        disabled={pageNumber === 1}
                                        onClick={() =>
                                            handlePageChange(pageNumber - 1)
                                        }
                                    />
                                    {renderPaginationItems()}
                                    <Pagination.Next
                                        disabled={pageNumber === TotalPages}
                                        onClick={() =>
                                            handlePageChange(pageNumber + 1)
                                        }
                                    />
                                </Pagination>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
