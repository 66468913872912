import React, { useState, useEffect } from "react";
import LoginServices from "../../services/LoginServices";
import { useNavigate } from "react-router-dom";
const PayByRazorPay = (props: any) => {
    console.log(props);
    const Navigate = useNavigate();
    const [orderId1, setOrderId1] = useState<string>("");
    const [userIpAddress, setUserIpAddress] = useState("");
    const userDetails = JSON.parse(localStorage.getItem("userdetails") || "");
    const options = {
        key: process.env.REACT_APP_RAZOR_PAY_KEY,
        client_id: process.env.REACT_APP_RAZOR_PAY_CLIENT_ID,
        client_secret: process.env.REACT_APP_RAZOR_PAY_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_RAZOR_PAY_GRANT_TYPE,
        mode: "test",
        amount: 0, //  = INR 1
        name: "Paygen",
        description: "Pay Bill",
        order_id: "",
        // image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
        handler: function (response: any) {
            console.log(response);
            const payload = {
                receiptId: localStorage.getItem("receiptId"),
                description: "",
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                amount: parseInt(props.passBlc),
            };
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.createRazorpayPayment(payload, config)
                .then((item: any) => {
                    console.log(item);
                    if (item.data.Status == 1) {
                        Navigate("/dashboard");
                        alert(item.data.Message);
                        window.location.reload();
                    } else {
                        alert(item?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            // alert(response.razorpay_payment_id);
        },
        prefill: {
            // name: `${userDetails?.firstName} ${userDetails?.lastName}`,
            contact: userDetails?.mobileNumber,
            email: userDetails?.emailId,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#6865F0",
            hide_topbar: false,
        },
    };

    const openPayModal = () => {
        console.log(props);

        if (props.passBlc > 500000) {
            alert("Deposits more than 5,00,000 not allowed");
            return;
        }

        let payload = {
            amount: parseInt(props.passBlc),
            paymentgateway: props.type,
            ipAddress: userIpAddress,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        LoginServices.createRazorpayOrder(payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    localStorage.setItem("receiptId", response.data.receiptId);
                    // setOrderId1(response.data.Razorpay_OrderPaymentID);
                    options.amount = props.passBlc * 100;
                    options.order_id = response.data.orderId;
                    if (props.type == "razorpay2") {
                        options.key = process.env.REACT_APP_RAZOR_PAY_KEY_TWO;
                        options.theme.color = "red";
                    }
                    var rzp1 = new (window as any).Razorpay(options);
                    rzp1.open();
                } else if (response.data.Status == 0) {
                    alert(response.data.Message);
                    console.log("Message");
                }
            })
            .catch(function (error) {
                alert(error.response.data.Message); // this is the part you need that catches 400 request
            });
    };
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    console.log(props.submitenabler, isNaN(props.passBlc));
    return (
        <>
            <div style={{ margin: "auto" }}>
                {/* isNaN(props.passBlc) */}

                <button
                    disabled={props.submitenabler}
                    onClick={openPayModal}
                    className="add-btn">
                    Add
                </button>
            </div>
            {/* {isNaN(props.passBlc) && props?.passBlc?.length &&
                <p className="error-text">Only Numbers Allowed</p>
            } */}
        </>
    );
};

export default PayByRazorPay;
