import React, { useEffect } from "react";
import FooterClasses from "./Footer.module.css";
import Logo from "../../../images/HomePage_Updated/Logo.png";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

import { FaUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";
function Footer() {
    return (
        <div className={FooterClasses["Container"]}>
            <div className={FooterClasses["Link1"]}>
                <div>
                    <img src={Logo} alt="Logo" />
                </div>
                <p>
                    Paygen is an Indian leading platform for all your utility
                    bill payments which provides real time payments.
                </p>
            </div>
            <div className={FooterClasses["Link2"]}>
                <h3>Company</h3>
                <NavLink to={"/terms-and-conditions"}>
                    Terms & Conditions
                </NavLink>
                <NavLink to={"/privacy-policy"}>Privacy policy</NavLink>
                <NavLink to={"/refund-policy"}>Refund Policy</NavLink>
            </div>
            <div className={FooterClasses["Link3"]}>
                <p style={{ fontSize: "1rem" }}>
                    Givit Infomatics Private Limited, <br />
                    #351,3-8-106,LPT Market,Chintalakunta,LB
                    Nagar,Hyderabad,Telangana-500074.
                    <br /> Email : support@paygen.in <br /> Phone : 9866592901.
                </p>
                {/* <p> Follow Us</p> */}

                {/*   <div className={FooterClasses["icons"]}>
                    <a href="https://www.facebook.com/" target="_blank">
                        <FaFacebookF />
                    </a>
                    <a href="https://www.facebook.com/" target="_blank">
                        <FaLinkedinIn />
                    </a>
                    <a href="https://www.facebook.com/" target="_blank">
                        <FaTwitter />
                    </a>
                </div> */}
            </div>
        </div>
    );
}

export default Footer;
