import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
// import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle } from "react-icons/fa";
import Spinner from "../spinner";
// const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: "#010080";
// `;

function KycPending() {
    const [spinner, setspinner] = useState(false);
    const { type } = useParams();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    let [loading, setLoading] = useState(false);
    const formdata = watch();
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const [submitEnabler, setSubmitEnabler] = useState<any>(false);
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [dropdown, setDropDown] = useState([{ blr_name: "Select Biller" }]);
    const [billervalues, setbillervalues] = useState<any>();
    const [billerid, setbillerid] = useState<any>();
    const userId = localStorage.getItem("userid");
    const mobilenumber = localStorage.getItem("mobilenumber");
    const selectBillerHandler = (e: any) => {
        console.log(e.target.value);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let body = { billerId: e.target.value };
        setbillerid(e.target.value);
        LoginServices.FetchBillerInfoPost(body, config)
            .then((response) => {
                console.log(response);
                if (response?.data.Status == 1) {
                    setbillervalues(response?.data?.BillerInfo);
                    localStorage.setItem(
                        "billerAdhoc",
                        response?.data?.BillerInfo?.billerAdhoc
                    );
                    sessionStorage.setItem(
                        "billerName",
                        response?.data?.BillerInfo?.billerName
                    );
                } else if (response.data.Status == 0) {
                    alert("something went wrong try again later");
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong try again later");
            });
        setSubmitEnabler(true);
    };
    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (userId) {
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);

    // showSection == 'biller-list'

    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        console.log(config);
        LoginServices.FetchBillerListByCategoryName(config, type, 0).then(
            (response) => {
                console.log("res", response);
                setDropDown(response?.data?.BillersList);
            }
        );
    }, []);

    const fetchbillHandler = (data: any) => {
        sessionStorage.setItem("usernumber", formdata.MobileNumber);
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        //mobilenumber

        let datacopy = { ...data };
        delete datacopy?.Biller;
        console.log(datacopy);
        const result = Object.entries(datacopy).map((e) => ({
            [e[0]]: e[1],
        }));

        const inputresult = result?.map((item: any) => {
            return {
                paramName: Object?.keys(item)[0],
                paramValue: Object?.values(item)[0],
            };
        });
        const body = {
            billerId: data?.Biller,
            mobileNumber: mobilenumber,
            Params: { inputParams: { input: [...inputresult] } },
        };
        LoginServices.FetchBillDetails(body, config)
            .then((response: any) => {
                console.log("111", response);
                setspinner(false);
                if (response?.data?.Status == 1) {
                    console.log("detail", response);
                    sessionStorage.setItem(
                        "billdetails",
                        JSON.stringify(response.data)
                    );
                    sessionStorage.setItem(
                        "amount",
                        response.data.BillDetails?.billerResponse?.billAmount
                    );
                    sessionStorage.setItem("billerid", data?.Biller);
                    navigate(`/dashboard/bill-payments/paybill-amount/${type}`);
                } else if (response?.data.Status == 0) {
                    alert(response?.data?.Message);
                }
            })
            .catch((err) => {
                setspinner(false);
                // console.log();
                alert(
                    err.response.data.Message ||
                        "some thing went wrong please try later "
                );
            });
    };

    const onSubmit = (data: any) => console.log(data);
    const inputchange = (e: any) => {
        const validate = new RegExp(e.currentTarget.getAttribute("data-regex"));
        if (validate.test(e.target.value)) {
            alert(
                `please Enter a Valid ${e.currentTarget.getAttribute(
                    "data-name"
                )}`
            );
        }
    };

    return (
        <>
            {spinner && <Spinner />}
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div style={{ backgroundColor: "#f6f7ff" }}>
                    <div className="paymentWrapper">
                        <div className="payment-header">
                            <div className="header-wrapper">
                                <h2>Pay Bills</h2>
                                <img
                                    style={{ alignSelf: "center" }}
                                    src="/assets/img/arrow.svg"
                                    alt=""
                                />
                                <h3>{type}</h3>
                            </div>
                            <img
                                className="payment-header-icon"
                                src="assets/billpayment/billpayment_header.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="biller-details-wrapper">
                        <form
                            className="biller-details"
                            onSubmit={handleSubmit(fetchbillHandler)}>
                            <div className="biller-detail">
                                <FaUserCircle size={35} className="icon" />
                                <select
                                    className="select"
                                    defaultValue={"Select-Biller"}
                                    {...register("Biller", {
                                        required: "Please select Biller",
                                        onChange: (e) => {
                                            selectBillerHandler(e);
                                        },
                                    })}>
                                    <option value="Select-Biller" disabled>
                                        Select Biller
                                    </option>
                                    {dropdown?.map((item: any) => (
                                        <option value={item?.blr_id}>
                                            {" "}
                                            {item?.blr_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {submitEnabler && (
                                <>
                                    {/* <div className="biller-detail">
                                        <img
                                            src="/assets/img/call.svg"
                                            className="call-icon"
                                            alt=""
                                        />
                                        <input
                                            className={
                                                errors?.MobileNumber &&
                                                "input-error"
                                            }
                                            id="billerinput"
                                            placeholder="Enter Mobile Number"
                                            type={"number"}
                                            {...register("MobileNumber", {
                                                required:
                                                    "Please Enter Mobile Number",
                                            })}
                                        />
                                        {errors?.MobileNumber && (
                                            <p style={{ color: "red" }}>
                                                {" "}
                                                {errors?.MobileNumber.message}
                                            </p>
                                        )}
                                    </div> */}
                                    {billervalues?.billerInputParams?.paramInfo?.map(
                                        (data: any) => {
                                            const name = data?.paramName;
                                            const boolValue =
                                                data?.isOptional == "true";
                                            console.log(name);
                                            return (
                                                <div className="biller-detail">
                                                    <input
                                                        className={
                                                            errors[`${name}`] &&
                                                            "input-error"
                                                        }
                                                        data-regex={data?.regEx}
                                                        minLength={
                                                            data?.minLength
                                                        }
                                                        maxLength={
                                                            data?.maxLength
                                                        }
                                                        data-name={
                                                            data?.paramName
                                                        }
                                                        id="billerinput"
                                                        placeholder={name}
                                                        type={"text"}
                                                        pattern={data?.regEx}
                                                        /* disabled={
                                                            data.paramName ===
                                                            "Registered Mobile Number"
                                                        } */
                                                        {...register(
                                                            `${name}`,
                                                            {
                                                                value:
                                                                    data.paramName ===
                                                                    "Registered Mobile Number"
                                                                        ? mobilenumber
                                                                        : formdata[
                                                                              name
                                                                          ],
                                                                required:
                                                                    !boolValue,
                                                                pattern: {
                                                                    value: data?.regEx,
                                                                    message: `invalid input`,
                                                                },
                                                            }
                                                        )}
                                                    />
                                                    {errors[`${name}`] && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                            }}>
                                                            Enter a Valid{" "}
                                                            {data?.paramName}
                                                        </p>
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                    <button type="submit">Fetch Bill</button>
                                </>
                            )}
                        </form>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
