import React from "react";
import BillClasses from "./Bills.module.css";
import UtilityBill from "../../../images/HomePage_Updated/utility_bill.png";
import support from "../../../images/HomePage_Updated/support.png";
import { BiSupport } from "react-icons/bi";
import utility from "../../../images/HomePage_Updated/utilityprocess.png";
import icon from "../../../images/HomePage_Updated/safemoney_icon.png";
import { Fade } from "react-awesome-reveal";

function Bills() {
    return (
        <div className={BillClasses["Container"]}>
            <div className={BillClasses["safe_money"]}>
                <img src={icon} alt="icon" />
                <div>
                    <h3>Your Money stays safe </h3>
                    <p>
                        You can assure your customers that their money and data
                        is safe. Paygen’s trust is unparalleled
                    </p>
                </div>
            </div>
            <div className={BillClasses["sub-wrapper"]}>
                <Fade direction="top-left" triggerOnce>
                    <h3>How to pay utility bills</h3>
                </Fade>
                <div className={BillClasses["utilityProcess"]}>
                    <img src={utility} alt="utility" />
                </div>
                <div className={BillClasses["cards"]}>
                    <Fade direction="left" triggerOnce>
                        <div className={BillClasses["card"]}>
                            <header>
                                <img src={UtilityBill} alt="1" />
                                <h3>Utility & Bill payments</h3>
                            </header>
                            <p>
                                Paying bill for the Users is easy with Paygen’s
                                Connect portal. Things do not go wrong often,
                                but when they do, you can count on us to solve
                                your problems.
                            </p>
                        </div>
                    </Fade>
                    <Fade direction="right" triggerOnce>
                        <div className={BillClasses["card"]}>
                            <header>
                                <img src={support} alt="1" />
                                <h3>
                                    For all issues, we provide 24x7 support.
                                </h3>
                            </header>
                        </div>
                    </Fade>

                    <button>
                        Contact US <BiSupport size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Bills;
