import { Modal } from "react-bootstrap";
import RazorpayBill from "../RazorBillPay";
import Signupclass from "./Confirm.module.css";

const Login = (props: any) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="login-modal1"
            centered
            backdrop={false}>
            <Modal.Body>
                <div className={Signupclass["border"]}>
                    <h3>Confirmation</h3>
                    <button
                        className={Signupclass["close"]}
                        onClick={() => {
                            props.onHide();
                        }}>
                        x
                    </button>
                </div>

                <div className={Signupclass["wrapper"]}>
                    <div className={Signupclass["Container"]}>
                        <p>
                            A Commission of {props.commissionPercentage}%( Rs{" "}
                            {Math.ceil(props.commissionamount - props.passBlc)})
                            is charged as proccessing Fee. Proceed ?
                        </p>
                        <div>
                            <RazorpayBill
                                passBlc={props.passBlc}
                                closeModal={props.onHide}
                                submitenabler={props.submitenabler}
                                setSpinner={props.setSpinner}
                                commissionamount={props.commissionamount}
                                payHandler={props.payHandler}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Login;
